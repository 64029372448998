export default class ShareWidget {
  constructor() {
    this.$body = $('body');
    this.shareToggle = '[data-share-widget-toggle]';
    this.shareWidget = '[data-share-widget]';

    this.$body.on('click', this.shareToggle, event => {
      event.preventDefault();
      const $target = $(event.currentTarget);
      const $widget = $target.next(this.shareWidget);

      $target.toggleClass('active');
      $widget.revealer();
    });
  }
}
