import debounce from 'just-debounce';
import PriceUpdater from '../components/PriceUpdater';

export default class Cart {
  constructor() {
    this.init();
  }

  init() {
    this.theme = window.Theme;

    const $shippingData = $('[data-shipping-calculator-data]');

    if ($shippingData.length) {
      try {
        this.theme.shippingCalculator = JSON.parse($shippingData.text());
      } catch (error) {}
    }


    this.Shopify = window.Shopify;

    this.$cartForm = $('[data-cart-form]');
    this.$shippingSubmit = $('[data-calculate-shipping]');
    this.$cartSubtotal = $('[data-cart-subtotal]');

    if (this.$cartForm.length) {
      this._bindEvents();
      this.PriceUpdater = new PriceUpdater();

      if (this.theme.shippingCalculator && this.$shippingSubmit.length) {
        this._initShipping();

        this.Shopify.onError = errors => {
          this._handleErrors(errors);
        };
      }
    }
  }

  _bindEvents() {
    $('[data-cart-item-increment]').on('click', (event) => {
      this._adjustValue(event, 1);
    });

    $('[data-cart-item-decrement]').on('click', (event) => {
      this._adjustValue(event, -1);
    });

    $('[data-product-quantity]').on('change', (event, itemData = false) => {
      event.preventDefault();

      const item = itemData || this._getCartItem(event);

      if (item.value > item.bounds.max) {
        item.$input.val(item.bounds.max);
        item.value = item.bounds.max;
        this._alert(Theme.cart.itemStock.replace('{stock}', item.bounds.max));
      }

      this._updateItem(item);
    });

    $('[data-cart-notes]').on('change', debounce((event) => {
      const note = $(event.currentTarget).val();
      this.Shopify.updateCartNote(note, () => {
        // Without an empty call back, Shopify returns the amount of products
        // that are present in the cart. It checks against it being a function.
        return true;
      });
    }, 200));
  }

  /**
   * Retrieve information about the cart item
   *
   * @param event
   * @returns {Object}
   *
   */
  _getCartItem(event) {
    const $target = $(event.currentTarget);
    const $productRow = $target.parents('[data-cart-item-row]');
    const $input = $productRow.find('[data-product-quantity]');
    const originalValue = parseInt($input.val(), 10);

    return {
      bounds: this._getBounds($input),
      $target,
      $productRow,
      productTitle: $productRow.find('[data-title]').text().trim(),
      $productPrice: $productRow.find('[data-item-price]'),
      lineItem: $productRow.data('cart-item-index'),
      $input,
      value: !isNaN(originalValue) ? originalValue : 1,
    };
  }

  /**
   * Update cart quantities on the server level to make them persistent
   *
   * @param event
   * @param adjustment
   * @private
   */
  _adjustValue(event, adjustment) {
    event.preventDefault();

    const item = this._getCartItem(event);
    let newQuantity = adjustment + item.value;
    newQuantity = Math.max(newQuantity, item.bounds.min);
    item.value = Math.min(newQuantity, item.bounds.max);

    item.$input.val(item.value).trigger('change', item);
  }

  /**
   * Sends a request to the server to update a single product
   *
   * @param item
   * @private
   */
  _updateItem(item) {
    $.ajax({
      type: 'POST',
      url: '/cart/change.js',
      dataType: 'json',
      data: {
        quantity: item.value,
        line: item.lineItem,
      },
      success: (response) => {
        // TODO: Add a callback to give the client a visual that its been updated?
        const cartTotal = response.total_price;
        const prices = [];

        prices.push({
          label: this.$cartSubtotal,
          price: cartTotal,
        });

        if (item.value === 0) {
          this._removeItem(item.lineItem, item.productTitle);
        } else {
          const itemTotal = response.items[item.lineItem - 1].line_price;
          prices.push({
            label: item.$productPrice,
            price: itemTotal,
          });
        }

        this._updatePrice(prices);
      },
    });
  }

  _updatePrice(prices){
    for (let i = 0; i < prices.length; i++) {
      const $label = prices[i].label;
      this.PriceUpdater.updatePrice($label, prices[i].price);
    }
  }

  /**
   * Determine the range of available inventory that can be purchase
   *
   * @param $input
   * @returns {Object}
   *          Minimum and maximum range of the product that can be purchased
   */
  _getBounds($input) {
    const min = 0;

    const max = $input.attr('data-product-inventory-max')
      ? parseInt($input.data('product-inventory-max'), 10)
      : Infinity;

    return {min, max};
  }

  /**
   * Remove an item from the DOM after it has been removed from the cart,
   * and recalculate line item indexes.
   *
   * @param lineItem
   * @param productTitle
   */
  _removeItem(lineItem, productTitle) {
    $(`[data-cart-item-index=${lineItem}]`).remove();
    this._alert(Theme.cart.itemRemoved.replace('{productTitle}', productTitle));

    // Recalculate cart row indexes
    const $cartItems = $('[data-cart-item-row]');
    for (let i = 0; i < $cartItems.length; i++) {
      $cartItems.eq(i).attr('data-cart-item-index', i + 1);
    }
  }

  _initShipping() {
    this.$shippingResponse = $('[data-shipping-response]');
    this.$shippingResponseMessage = $('[data-shipping-response-message]');
    this.$shippingResponseRates = $('[data-shipping-response-rates]');

    this.$shippingSubmit.on('click', (event) => {
      event.preventDefault();
      this._calculateShipping();
    });

    // Prevents hitting 'enter' in these fields from accidental submitting the cart
    $('#address_zip').on('keypress', (event) => {
      if (event.keyCode === 10 || event.keyCode === 13) {
        event.preventDefault();
        this.$shippingSubmit.trigger('click');
      }
    });

    this._shippingCalculator();
  }

  _shippingCalculator() {
    this.Shopify.Cart.ShippingCalculator.show({
      submitButton: this.theme.shippingCalculator.submitButton,
      submitButtonDisabled: this.theme.shippingCalculator.submitButtonDisabled,
      wrapperId: 'shipping-calculator-response',
      customerIsLoggedIn: this.theme.customerLoggedIn,
      moneyFormat: this.theme.currency.moneyFormat,
    });
  }

  _calculateShipping() {
    this.$shippingSubmit
      .text(this.theme.shippingCalculator.submitButtonDisabled)
      .attr('disabled', true);

    const shippingAddress = {};
    shippingAddress.country = $('#address_country').val() || '';
    shippingAddress.province = $('#address_province').val() || '';
    shippingAddress.zip = $('#address_zip').val() || '';


    //Creates an ajax request which returns shipping information
    this.Shopify.getCartShippingRatesForDestination(shippingAddress, (response, shippingAddress) => {

      const addressBase = [];

      if (shippingAddress.zip.length) {
        addressBase.push(shippingAddress.zip.trim());
      }

      if (shippingAddress.province.length) {
        addressBase.push(shippingAddress.province);
      }

      if (shippingAddress.country.length) {
        addressBase.push(shippingAddress.country);
      }

      const address = addressBase.join(', ');

      // Hide the response so that it can be populated smoothly
      this.$shippingResponse.toggleClass('visible', false);

      // Empty out contents
      this.$shippingResponseMessage.empty();
      this.$shippingResponseRates.empty();

      let responseText = '';

      if (response.length > 1) {
        const firstRate = this.Shopify.Cart.ShippingCalculator.formatRate(response[0].price);
        responseText = this.theme.shippingCalculator.multiRates
          .replace('*address*', address)
          .replace('*number_of_rates*', response.length)
          .replace('*rate*', `<span data-money>${firstRate}</span>`);
      } else if (response.length === 1) {
        responseText = this.theme.shippingCalculator.oneRate.replace('*address*', address);
      } else {
        responseText = this.theme.shippingCalculator.noRates;
      }

      this.$shippingResponseMessage.html(responseText);

      for (let i = 0; i < response.length; i++) {
        const rate = response[i];
        const price = this.Shopify.Cart.ShippingCalculator.formatRate(rate.price);
        const rateValues = this.theme.shippingCalculator.rateValues
          .replace('*rate_title*', rate.name)
          .replace('*rate*', `<span data-money>${price}</span>`);

        this.$shippingResponseRates.append(`<li>${rateValues}</li>`);
      }

      // Reset the calculating button so it can be used again
      this._resetShippingButton();

      this.$shippingResponse.toggleClass('visible', true);
    });
  }

  _resetShippingButton(){
    this.$shippingSubmit
        .text(this.theme.shippingCalculator.submitButton)
        .attr('disabled', false);
  }

  _handleErrors(errors) {
    let errorMessage = $.parseJSON(errors.responseText);
    errorMessage = this.theme.shippingCalculator.errorMessage.replace('*error_message*', errorMessage.zip);
    this._alert(errorMessage);
    this._resetShippingButton();
  }

  _alert(message) {
    $('[data-cart-message]').html(`<div class="cart-message">${message}</div>`);
  }
}
