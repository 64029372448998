export default class TagList {
  constructor() {
    this.init();
  }

  init() {
    this.$tags = $('.section-tags');
    this.$tagList = $('.section-tag-list');

    if (this.$tagList.length) {
      this._bindEvents();
      this._centerSelectedTags();
      this._addScrollClasses();
    }
  }

  _bindEvents() {
    this.$tagList.on('scroll', event => this._addScrollClasses());
    $(window).on('resize', event => this._addScrollClasses());
  }

  _centerSelectedTags() {
    const $activeTag = this.$tags.find('.section-tag-active:first');
    if (!$activeTag.length) return;

    const activePosition = $activeTag.position().left;
    const targetPosition = window.innerWidth / 2;
    const tagOffset = $activeTag.width() / 2;
    const delta = activePosition - targetPosition + tagOffset;
    this.$tagList.scrollLeft(delta);
  }

  _addScrollClasses() {
    const scrollLeft = this.$tagList.scrollLeft();
    const scrollWidth = this.$tagList[0].scrollWidth;
    const width = this.$tagList.width();

    this.$tags.toggleClass('section-tags-overflow-left', scrollLeft > 0);
    this.$tags.toggleClass('section-tags-overflow-right', scrollLeft < scrollWidth - width);
  }
}
