// Account pages js
export default class Account {
  constructor() {

    // Deal with showing / hiding elements on login page
    const $formWrapper = $('[data-login-form]');
    if ($formWrapper) {
      $formWrapper.on('click tap', '[data-toggle-passwordform]', () => { 
        $formWrapper.toggleClass('show-password-form');
      })
    }

    this.$customerAddresses = $('[data-address-id]');
    if (this.$customerAddresses) { this.addressPage(); }
  }

  addressPage() {
    const $addressEditLinks = $('[data-edit-address]')
    if ($addressEditLinks) {
      $addressEditLinks.on('click tap', (event) => {
        const itemId = $(event.target).attr('data-edit-address');
        $(`[data-address-id="${itemId}"]`).toggle();
      });
    }

    const $addressDeleteLinks = $('[data-delete-address]')
    if ($addressDeleteLinks) {
      $addressDeleteLinks.on('click tap', (event) => {
        const itemId = $(event.target).attr('data-delete-address');
        Shopify.CustomerAddress.destroy(itemId)
      });
    }

    this.$customerAddresses.each(function () {
      const id = $(this).attr('data-address-id');

      // Initiate provinces for the New Address form
      new Shopify.CountryProvinceSelector(
        `customer-addr-${id}-country`,
        `customer-addr-${id}-province`, 
        {hideElement: `address-province-container-${id}`}
      );
    });

  }
}
