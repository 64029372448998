export default class Search {
  constructor() {
    this.$search = $('[data-search-input]');

    this._init();
  }

  _init() {
    if (this.$search.length) {
      this.$search.focus();
    }
  }
}
