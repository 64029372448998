import CurrencyConverter from 'shopify-currency-converter';

export default class Sidebar {
  constructor() {
    this.$body = $(document.body);
    this.$sidebar = $('.main-sidebar');
    this.$productSidebar = $('.product-sidebar');
    this.$overlay = $('.content-overlay');
    this.currency = window.Theme.currency;
    this.enableConverter = this.currency.enable && window.Currency;
    this.$switcher = null;

    if (this.enableConverter) {
      this.initCurrencyConverter();
    }

    this._bindEvents();
  }

  isExtended() {
    return this.$body.hasClass('sidebar-extended');
  }

  toggleExtended(callback, toggle) {
    const state = toggle === undefined
      ? !this.isExtended()
      : toggle;

    if (callback) {
      const $el = this.$productSidebar.length
        ? this.$productSidebar
        : this.$sidebar;

      $el.one('trend', callback);
    }

    this.$body.toggleClass('sidebar-extended', state);
    this.toggleOverlay(state);
  }

  toggleOverlay(toggle) {
    if (toggle === undefined) {
      this.$overlay.revealer();
    } else {
      this.$overlay.revealer(toggle ? 'show' : 'hide');
    }

    this.$body.toggleClass('overlay-visible', toggle);
  }

  initCurrencyConverter() {
    this.$converter = $('[data-currency-converter]');

    CurrencyConverter.init({
      switcherSelector: '[data-currency-converter]',
      priceSelector: '[data-money], span.money',
      shopCurrency: this.currency.shopCurrency,
      defaultCurrency: this.currency.defaultCurrency,
      displayFormat: this.currency.displayFormat,
      moneyFormat: this.currency.moneyFormat,
      moneyFormatNoCurrency: this.currency.moneyFormatNoCurrency,
      moneyFormatCurrency: this.currency.moneyFormatCurrency,
    });

    this.$converter.on('change.converter', event => {
      CurrencyConverter.setCurrency(event.currentTarget.value);
    });
  }

  removeCurrencyConverter() {
    if (this.enableConverter) {
      this.$converter.off('change.converter');
    }
  }

  _bindEvents() {
    // Open/close nav modal
    this.$body.on('click', '[data-overnav-open]', (event) => {
      event.preventDefault();
      $('[data-overnav]').revealer('show');
      $('[data-overnav-overlay]').revealer('show');
    });

    this.$body.on('click', '[data-overnav-close]', (event) => {
      event.preventDefault();
      $('[data-overnav]').revealer('hide');
      $('[data-overnav-overlay]').revealer('hide');
    });

    this.$body.on('click', '[data-overnav-overlay]', (event) => {
      event.preventDefault();
      $('[data-overnav]').revealer('hide');
      $('[data-overnav-overlay]').revealer('hide');
    });

    // Open/close navigation submenus
    this.$body.on('click', '.navigation-parent-link', (event) => {
      event.preventDefault();
      const $link = $(event.currentTarget);
      const $submenu = $link.siblings('.navigation-menu');
      $submenu.slideToggle(200);
    });
  }
}
