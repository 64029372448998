import CurrencyConverter from 'shopify-currency-converter';

export default class PriceUpdater {
  constructor() {
    this.theme = window.Theme;
    this.Shopify = window.Shopify;
    this.Currency = window.Currency;

    this.moneyFormat = this.theme.currency.moneyFormat;
  }

  updatePrice($el, price) {
    // Assign base shop based currency to data attributes and text
    const formattedPrice = this.Shopify.formatMoney(price, this.moneyFormat);
    $el.text(formattedPrice);

    if (this.theme.currency.enable && this.Currency) {
      CurrencyConverter.update($el[0]);
    }
  }
}
