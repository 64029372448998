/**
 * Locates an SVG icon based on its tag and returns the shape from the first `<path>`
 * @param iconName
 * @returns {*}
 */
export default function svgPath(iconName){
  const $icon = $(`#${iconName}`);
  const $path = $icon.find('path').eq(0);

  return $path.attr('d');
}
