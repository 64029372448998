import 'requestanimationframe';
import Flickity from 'flickity';
import fitvids from 'fitvids';
import svgPath from '../assets/svgPath';
import PriceUpdater from './PriceUpdater';

export default class Product {
  constructor(theme) {
    this.theme = theme;
    this.init();
  }

  init() {
    this.$body = $(document.body);
    this.$window = $(window);
    this.$document = $(document);
    this.$productContainer = $('[data-product-list]');

    this.cachedProductSelector = 'script[type="application/vnd.vogue.product+html"]';
    this.extendedOpenSelector = '[data-product-extended-open]';
    this.extendedCloseSelector = '[data-product-extended-close]';
    this.extendedMobileSelector = '[data-product-extended-mobile]';
    this.productSelector = '[data-product-id]';
    this.productHeaderSelector = '.product-details-header';
    this.products = [];

    // Sidebar settings
    this.hasSidebar = window.Theme.hasSidebar;
    this.gridSpacing = parseInt(window.Theme.gridSpacing, 10) || 0;
    this.sidebarWidth = 300;
    this.enableHistory = true;

    if (window.Shopify && window.Shopify.preview_host) {
      this.enableHistory = false;
    }

    if (this.$productContainer.length) {
      this._bindEvents();
      this._addProducts(this.productSelector, false);
      this._updateProductPositions();
      this._runScrollEffect();
      this._initProductOptions();
      this.PriceUpdater = new PriceUpdater();
      this._fetchProducts(1);
    }
  }

  remove() {
    if (this.$extendedProduct.length) {
      this._closeExtendedDetails(this.$extendedProduct);
    }

    this.$body.off('.vogue-product');
    this.$window.off('.vogue-product');
    this.cancelScrollEffect = true;
  }

  /**
   * Load products from our in-page cache.
   */
  _fetchProducts(limit) {
    $(this.cachedProductSelector)
      .slice(0, limit)
      .each((index, el) => this._addProducts(el.innerHTML))
      .remove();
  }

  /**
   * Register products with our scroll effects handler.
   */
  _addProducts(products, addToPage = true) {
    $(products).each((index, el) => {
      const $el = $(el);

      // Add to the page
      if (addToPage) {
        this.$productContainer
          .find('.product:last')
          .after($el);
      }

      // Cache elements so we can access them quickly in the scroll handler
      this.products.push({
        $body: $el.find('.product-body'),
        $details: $el.find('.product-details'),
        $gallery: $el.find('.product-gallery'),
        $mobileHeader: $el.find('.product-details-header-mobile'),
      });
    });

    // Make any videos responsive
    fitvids('.product-description-full.rte');
  }

  _bindEvents() {
    // Load in new products as we scroll down the page
    this.$window.on('scroll.vogue-product', event => {
      if (this._isMobile()) return;

      const percent = this.$window.scrollTop() / (this.$document.height() - this.$window.height());

      if (percent < 0.6) return;
      this._fetchProducts(4);
    });

    // Scroll down to the product details on mobile
    this.$body.on('click.vogue-product', this.extendedMobileSelector, event => {
      event.preventDefault();
      const $target = $(event.currentTarget);
      const $el = $target.parents(this.productSelector).find('.product-details');
      this._ensureElementInViewport($el[0]);
    });

    // Open the extended details panel
    this.$body.on('click.vogue-product', this.extendedOpenSelector, event => {
      event.preventDefault();
      const $target = $(event.currentTarget);
      this.$extendedProduct = $target.parents(this.productSelector);
      this._openExtendedDetails(this.$extendedProduct);
    });

    // Close the extended details panel
    this.$body.on('click.vogue-product', this.extendedCloseSelector, event => {
      event.preventDefault();
      this._closeExtendedDetails(this.$extendedProduct);
    });

    // Close the extended details panel when resizing down to mobile
    this.$window.on('resize.vogue-product', event => {
      if (this.$extendedProduct && this._isMobile()) {
        this._closeExtendedDetails(this.$extendedProduct);
        this.theme.sidebar.toggleExtended(null, false);
      }
    });
  }

  /**
   * Show the extended details for a product.
   *
   * @param {jQuery} $product
   */
  _openExtendedDetails($product) {
    // Scroll product into view
    const gallery = $product.find('.product-gallery')[0];
    this._ensureElementInViewport(gallery);

    // Animate open the extended details
    $product.find('.product-description-excerpt').revealer('show');

    $product.find('.product-options').slideDown();

    this.theme.sidebar.toggleExtended(() => {
      $product
        .addClass('product-details-extended')
        .find('.product-description-full')
        .revealer('show');

      $product.find('.product-related').revealer('show');

      // Create slideshow
      this._createProductSlideshow($product);
    }, true);

    // Resizing the window when a product is extended causes problems
    this.$window.on('resize.vogue-extended-resize', event => {
      this._ensureElementInViewport(gallery, false);
    });
  }

  /**
   * Hide the extend details for a product.
   *
   * @param {jQuery} $product
   */
  _closeExtendedDetails($product) {
    // Remove the slideshow
    this._destroyProductSlideshow();

    $product.find('.product-options').slideUp();

    $product.find('.product-related').revealer('hide');

    // Animate out the extended details
    $product
      .find('.product-description-full')
      .revealer('hide')
      .one('revealer-hide', event => {
        $product
          .removeClass('product-details-extended')
          .find('.product-description-excerpt')
          .revealer('hide');

        this.theme.sidebar.toggleExtended(null, false);
      });

    // Remove the resize handler--it's only needed when the details are extended
    this.$window.off('resize.vogue-extended-resize');
  }

  /**
   * Create a slideshow for the product images when the extended
   * details are opened.
   */
  _createProductSlideshow($product) {
    const $gallery = $('.product-gallery', $product);
    const $slideshow = $('<div>').addClass('product-slideshow').revealer('hide', true);

    $gallery.find('.product-image img').each(function(index, el){
      $('<div>')
        .addClass('product-slideshow-image')
        .css('background-image', `url(${$(el).attr('src')})`)
        .appendTo($slideshow);
    });

    this.$body.append($slideshow);

    this.slideshow = new Flickity($slideshow[0], {
      bgLazyLoad: 2,
      pageDots: false,
      prevNextButtons: $gallery.find('.product-image').length > 1,
      arrowShape: svgPath('icon-flickity-arrow'),
    });

    // Select first available variant image
    const variant = this._getSelectedProductVariant($product);

    if (variant.featured_image) {
      this.slideshow.select(variant.featured_image.position - 1);
    }

    // Show the slideshow
    $slideshow.revealer('show');
  }

  /**
   * Remove the current product slideshow.
   */
  _destroyProductSlideshow() {
    const $slideshow = $('.product-slideshow');

    $slideshow
      .revealer('hide')
      .one('revealer-hide', event => {
        $slideshow.remove();
      });

    this.slideshow = null;
  }

  /**
   * Scroll to a position where the element is completely visible.
   *
   * @param {Element} el
   */
  _ensureElementInViewport(el, animate = true) {
    const productRect = el.getBoundingClientRect();
    const offsetTop = Math.ceil(productRect.top);
    const offsetBottom = Math.ceil(window.innerHeight - productRect.bottom);
    const duration = animate ? 300 : 0;

    if (offsetTop > 0) {
      $('html, body').animate({ scrollTop: `+=${offsetTop}px` }, duration);
    } else if (offsetBottom > 0) {
      $('html, body').animate({ scrollTop: `-=${offsetBottom}px` }, duration);
    }
  }

  /**
   * As we scroll down the page, animate in the next product details sidebar.
   */
  _runScrollEffect() {
    if (this.cancelScrollEffect) {
      this.cancelScrollEffect = false;
      return;
    }

    window.requestAnimationFrame(() => {
      this._updateProductPositions();
      this._runScrollEffect();
    });
  }

  _isMobile() {
    return window.innerWidth < 720;
  }

  _updateProductPositions() {
    const screenHeight = window.innerHeight;
    const screenHeightHalf = screenHeight / 2;
    const $header = $('[data-main-sidebar-header]');
    const headerHeight = this.hasSidebar ? $header.outerHeight() : 0;
    const height = screenHeight - headerHeight - (this.gridSpacing * 2);
    const sidebarOffset = this._isMobile() ? 0 : this.sidebarWidth;

    // Activate the grid mask if needed (see _sidebar.scss)
    $header.toggleClass('needs-grid-mask', window.scrollY > this.gridSpacing);

    // Position products
    this.products.forEach(product => {
      const detailsHeight = product.$details.height();
      const productRect = product.$body[0].getBoundingClientRect();
      const pinDetailsToTop = detailsHeight + headerHeight > product.$gallery.height();

      // Detect which product we are on, and update the URL to match
      if (productRect.top < screenHeightHalf && productRect.bottom > screenHeightHalf) {
        this._setHistory(product);
      }

      // Mobile positions
      if (this._isMobile()) {
        const mobileHeaderHeight = product.$mobileHeader.data('height');
        const galleryRect = product.$gallery[0].getBoundingClientRect();
        const isFloating = galleryRect.bottom - screenHeight + mobileHeaderHeight > 0;
        const isInFrame = galleryRect.top - screenHeight < 0;

        // Reset desktop positions
        product.$details.css({
          position: 'relative',
          top: 'auto',
          left: 'auto',
          bottom: 'auto',
          maxHeight: 'none',
        });

        // Position mobile header
        product.$mobileHeader.toggleClass('is-floating', isFloating);

        product.$mobileHeader.css({
          position: isFloating ? 'fixed' : 'static',
          right: this.gridSpacing,
          bottom: 0,
          left: this.gridSpacing,
          display: isInFrame ? '' : 'none',
        });
      }

      // Pin to bottom of the product
      else if (!pinDetailsToTop && productRect.bottom - detailsHeight - (this.gridSpacing * 2) - headerHeight < 0) {
        product.$details.css({
          position: 'absolute',
          top: 'auto',
          left: -sidebarOffset,
          bottom: this.gridSpacing,
          maxHeight: height,
        });
      }

      // Pin to the top of the viewport
      else if (!pinDetailsToTop && productRect.top - headerHeight <= 0) {
        product.$details.css({
          position: 'fixed',
          top: headerHeight + this.gridSpacing,
          left: this.gridSpacing,
          bottom: 'auto',
          maxHeight: height,
        });
      }

      // Pin to the top of the product
      else {
        const topOffset = pinDetailsToTop
          ? Math.min(headerHeight, Math.max(0, headerHeight - productRect.top))
          : 0;

        product.$details.css({
          position: 'absolute',
          top: this.gridSpacing + topOffset,
          left: -sidebarOffset,
          bottom: 'auto',
          maxHeight: height,
        });
      }
    });
  }

  /**
   * Update the browser's URL to match a specific product URL.
   */
  _setHistory(product) {
    if (!this.enableHistory || !window.history || !window.history.replaceState) {
      return;
    }

    const productUrl = product.$details.find('meta[itemprop=url]').attr('content');

    if (window.location.href !== productUrl) {
      // Make sure an exception doesn't crash the whole page
      // For example, Safari throws a `SecurityError` if the URL is updated too often
      try {
        window.history.replaceState({}, '', productUrl);
      } catch(error) {}
    }
  }

  /**
   * Create our custom product option handler.
   */
  _initProductOptions() {
    this.$body.on('change', '[data-option-input]', event => {
      const $input = $(event.currentTarget);
      const $product = $input.parents('.product-details');

      // Update the selected variant ID
      const variant = this._getSelectedProductVariant($product);
      this._updateProductVariant($product, variant);
    });
  }

  _updateProductVariant($product, variant) {
    // Mark unavailable variants
    $product.toggleClass('product-variant-unavailable', variant.id === null);
    $product.toggleClass('product-variant-soldout', variant.id !== null && !variant.available);

    // Update our select element, which is the source of truth for
    // the currently selected variant
    $product.find('[data-product-variants]').val(variant.id);

    // Update price
    const $priceOriginal = $product.find('[data-price-original]');
    this.PriceUpdater.updatePrice($priceOriginal, variant.price);

    // Update compare price
    const $priceCompare = $product.find('[data-price-compare]');
    this.PriceUpdater.updatePrice($priceCompare, variant.compare_at_price);
    $priceCompare.toggleClass('product-price-has-compare', variant.compare_at_price !== null);

    // Select slideshow slide
    if (variant.featured_image && this.slideshow) {
      this.slideshow.select(variant.featured_image.position - 1);
    }
  }

  /**
   * Get the selected variant details for a specific product.
   *
   * @param {jQuery} $product
   * @return {Object} A variant object.
   */
  _getSelectedProductVariant($product) {
    const $inputs = $product.find('[data-option-input]');
    const $select = $product.find('[data-product-variants]');
    const product = $select.data('product');

    // Grab the list of selected options
    const options = {};

    $inputs.filter(':checked').each((index, el) => {
      const $input = $(el);
      const id = $input.data('option-index');
      options[`option${id}`] = $input.val();
    });

    // Grab the variant
    return this._getVariantFromOptions(product, options) || {
      id: null,
      price: 0,
      compare_at_price: null,
      available: false,
    };
  }

  /**
   * Get variant details for a specific set of options.
   *
   * @param {Object} product
   *        A product, as made available from the liquid template.
   *
   * @param {Object} options
   *        A map of option keys to values. One of: option1, option2, option3.
   *
   * @return {Object} A variant object, or `null` if none found.
   */
  _getVariantFromOptions(product, options) {
    if (!product) return;
    if (!product.variants) return;

    for (let i = 0; i < product.variants.length; i++) {
      const variant = product.variants[i];
      let isMatch = true;

      for (const optionName in options) {
        if (variant[optionName] !== options[optionName]) {
          isMatch = false;
          break;
        }
      }

      if (isMatch) return variant;
    }
  }
}
