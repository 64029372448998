import Masonry from 'masonry-layout';
import imagesLoaded from 'imagesloaded';

export default class Blog {
  constructor() {
    this.blogSelector = '[data-article-list]';
    this.init();
  }

  init() {
    this.blogMasonry = document.querySelector(this.blogSelector);

    if (this.blogMasonry) {
      this._blogMasonry();
    }
  }

  remove() {
    if (this.grid) {
      this.grid.destroy();
      this.grid = null;
    }
  }

  _blogMasonry() {
    // Set up masonry grid
    this.grid = new Masonry(this.blogMasonry, {
      itemSelector: '[data-article-item]',
      columnWidth:  '.article-item-grid-sizer',
      gutter: '.article-item-gutter-sizer',
      percentPosition: true,
    });

    const loader = imagesLoaded(this.blogSelector);

    loader.on('progress', (instance, image) => {
      // Add loaded class to product
      const $img = $(image.img);
      const $item = $img.parents('.article-item-image');
      $item.addClass('article-item-image-loaded');

      // Re-layout grid
      this.grid.layout();
    });
  }
}

