import Flickity from 'flickity';
import debounce from 'just-debounce';
import svgPath from '../assets/svgPath';
import imagesLoaded from 'imagesloaded';

export default class Lookbook {
  constructor() {
    this.$body = $(document.body);

    this.slideShowSelector = '[data-lookbook-slider]';
    this.$slideShows = $(this.slideShowSelector);

    if (this.$slideShows) {
      this._initSlideShows();
    }
  }

  _initSlideShows() {

    // arrowShape is drawn on a 100x100 ViewBox, only need the left arrow
    const arrowShape = svgPath('icon-flickity-arrow');

    const flickityOptions = {
      autoPlay: 0,
      cellSelector: '.lookbook-slide',
      cellAlign: 'center',
      pageDots: false,
      prevNextButtons: true,
      wrapAround: true,
      arrowShape,
    };

    const slideShows = document.querySelectorAll(this.slideShowSelector);

    for (let index = 0; index < slideShows.length; index++) {
      /*
        Initialises the slider after every image in that slideshow has been loaded

        TODO: Can we initialise the slideshow when one image has loaded, and then add images into the slideshow as they load?
       */
      imagesLoaded(slideShows[index], () => {
        const slideShow = new Flickity(slideShows[index], flickityOptions);
        this._bindSlideShow(slideShow);
      });
    }

    this._bindSlideShows();
  }

  _bindSlideShow(slideShow) {
    const $slideShow = $(slideShow.element);
    const $viewport = $slideShow.find('.flickity-viewport');

    /*
     On slide change, find the new height of the slide and set it as the
     height of the slider

     TODO: On upgrade to Flickity 2, change this to `select`
     */
    slideShow.on('cellSelect', () => {
      $slideShow.trigger('heightUpdate');
    });

    $slideShow.on('heightUpdate', () => {
      this._setSlideHeight($viewport, $(slideShow.selectedElement));
    });

    // Sets the Slider to the height of the first slide
    $slideShow.trigger('heightUpdate');
  }

  _bindSlideShows() {
    $(window).on('resize', debounce(() => {
      this.$slideShows.trigger('heightUpdate');
    }, 200));
  }

  _setSlideHeight($viewport, $selectedElement) {
    $viewport.height($selectedElement.height());
  }
}
