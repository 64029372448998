import ThemeEditor from './components/ThemeEditor';
import Cart from './templates/Cart';
import Home from './templates/Home';
import Lookbook from './templates/Lookbook';
import Account from './templates/Account';
import Blog from './templates/Blog';
import Search from './templates/Search';
import ShareWidget from './components/ShareWidget';
import Sidebar from './components/Sidebar';
import Product from './components/Product';
import TagList from './components/TagList';
import RTE from './components/RTE';

// jQuery plugins
import 'jquery-trend';
import 'jquery-revealer';

export default class Vogue {
  constructor() {
    this._init();
  }

  _init() {
    // Components
    this.shareWidget = new ShareWidget();
    this.tagList = new TagList();
    this.sidebar = new Sidebar();
    this.rte = new RTE();

    // Page Templates
    this.home = new Home();
    this.cart = new Cart();
    this.lookbook = new Lookbook();
    this.product = new Product(this);
    this.account = new Account();
    this.blog = new Blog();
    this.search = new Search();

    // Theme Editor interactions
    this.themeEditor = new ThemeEditor(this);
  }
}

new Vogue();
