import debounce from 'just-debounce';

export default class Home {
  constructor() {
    this.sectionContent = '[data-home-section-content]';
    this.gridSpacing = parseInt(window.Theme.gridSpacing, 10) || 0;

    this._init();
  }

  _init() {
    this.updateSectionHeights();

    $(window).on('resize', debounce(() => {
      this.updateSectionHeights();
    }, 200));
  }

  updateSectionHeights() {
    $('[data-home-section]').each((index, section) => {
      const $section = $(section);
      const $content = $(this.sectionContent, $section);

      if (!$content.length) return;
      if ($section.hasClass('home-section-height-image-height')) return;

      const contentHeight = Math.ceil($content.outerHeight(true));
      const sectionBaseHeight = Math.ceil($section.outerHeight(true));

      if (!$section.attr('data-original-height')) {
        $section.attr('data-original-height', sectionBaseHeight + (this.gridSpacing * 2));
      }

      const sectionHeight = parseInt($section.attr('data-original-height'), 10);

      if (contentHeight > sectionHeight) {
        $section.css('height', contentHeight);
      } else {
        $section.css('height', '');
      }
    });
  }
}
