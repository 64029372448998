export default class {
  constructor(theme) {
    this.theme = theme;

    $(document).on('shopify:section:load', event => this._addHeaderClasses());
    $(document).on('shopify:section:load', event => this._homepage(event.target));
    $(document).on('shopify:section:load', event => this._tagList(event.target));
    $(document).on('shopify:section:load', event => this._blog(event.target, true));
    $(document).on('shopify:section:unload', event => this._blog(event.target, false));
    $(document).on('shopify:section:load', event => this._cart(event.target));
    $(document).on('shopify:section:load', event => this._product(event.target, true));
    $(document).on('shopify:section:unload', event => this._sideBar(event.target, false));
    $(document).on('shopify:section:load', event => this._product(event.target, true));
    $(document).on('shopify:section:unload', event => this._sideBar(event.target, false));
  }

  _sideBar(section, load) {
    if ($(section).attr('id') === 'shopify-section-header') {
      if (load) {
        this.theme.product.initCurrencyConverter();
      } else {
        this.theme.product.removeCurrencyConverter();
      }
    }
  }

  _product(section, load) {
    if ($(section).attr('id') === 'shopify-section-page-product') {
      if (load) {
        this.theme.product.init();
      } else {
        this.theme.product.remove();
      }
    }
  }

  _blog(section, load) {
    if ($(section).attr('id') === 'shopify-section-page-blog') {
      if (load) {
        this.theme.blog.init();
      } else {
        this.theme.blog.remove();
      }
    }
  }

  _homepage(section) {
    if ($(section).find('[data-home-section]').length) {
      this.theme.home.updateSectionHeights();
    }
  }

  _cart(section) {
    if ($(section).attr('id') === 'shopify-section-page-cart') {
      this.theme.cart.init();
    }
  }

  _tagList(section) {
    if ($('.section-tags').length) {
      this.theme.tagList.init();
    }
  }

  _addHeaderClasses() {
    const hasSidebar = $('[data-header-sidebar]').data('header-sidebar');

    $(document.body).toggleClass('sidebar-always-visible', hasSidebar);
    $(document.body).toggleClass('sidebar-always-hidden', !hasSidebar);

    if (window.Theme) Theme.hasSidebar = hasSidebar;
  }
}
